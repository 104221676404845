<script setup>
import {ref} from "vue";

const props = defineProps(['value', 'field', 'label', 'hasError', 'isRequired']);

const isRequired = ref(props.isRequired);

</script>

<template>
  <label
      :for="field"
      class="form-label"
  >
    {{ label }} <span v-if="isRequired">*</span>
  </label>
  <input
      type="text"
      :name="field"
      :id="field"
      class="form-control form-control-lg rounded-0 p2 p-md-4"
      :class="{ 'is-invalid': hasError }"
      :value="value"
      :required="isRequired"
  />
  <div id="validationFor{{field}}" class="invalid-feedback">
    Bitte dieses Feld korrekt befüllen.
  </div>
</template>

<style scoped>

</style>