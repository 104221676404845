<script setup>
import {ref} from 'vue'
import Text from './Forms/Text.vue'
import AddNewFields from "./Forms/AddNewFields.vue";

const props = defineProps({
  invoice_address_differs: String,

  invoice_zip: String,
  invoice_zip_error: String,
  invoice_city: String,
  invoice_city_error: String,
  invoice_street: String,
  invoice_street_error: String,
  invoice_street_number: String,
  invoice_street_number_error: String,
  invoice_address_additional: String,
})

const invoiceZipError = ref(props.invoice_zip_error)
const invoiceCityError = ref(props.invoice_city_error)
const invoiceStreetError = ref(props.invoice_street_error)
const invoiceStreetNumberError = ref(props.invoice_street_number_error)

const invoiceAddressDiffers = ref(props.invoice_address_differs)
</script>

<template>
  <div class="col-md-12 pt-4">
    <AddNewFields
        field="invoice_address_differs"
        label="Rechnungsadresse unterscheidet sich von Lieferadresse"
        v-model:addNewFields="invoiceAddressDiffers"
    />
  </div>

  <h2
      class="pt-3 mt-3 pt-md-5 mt-md-5"
      v-if="invoiceAddressDiffers"
  >
    Rechnungsadresse
  </h2>
  <hr
      class="border-white pb-2"
      v-if="invoiceAddressDiffers"
  >

  <div
      class="col-md-3 pt-4"
      v-if="invoiceAddressDiffers"
  >
    <Text
        field="invoice_zip"
        label="Postleitzahl 2"
        :value="invoice_zip"
        :has-error="invoiceZipError"
        :is-required="true"
    />
  </div>
  <div
      class="col-md-9 pt-4"
      v-if="invoiceAddressDiffers"
  >
    <Text
        field="invoice_city"
        label="Ort 2"
        :value="invoice_city"
        :has-error="invoiceCityError"
        :is-required="true"
    />
  </div>
  <div
      class="col-md-6 pt-4"
      v-if="invoiceAddressDiffers"
  >
    <Text
        field="invoice_street"
        label="Strasse 2"
        :value="invoice_street"
        :has-error="invoiceStreetError"
        :is-required="true"
    />
  </div>
  <div
      class="col-md-3 pt-4"
      v-if="invoiceAddressDiffers"
  >
    <Text
        field="invoice_street_number"
        label="Strassennummer 2"
        :value="invoice_street_number"
        :has-error="invoiceStreetNumberError"
        :is-required="true"
    />
  </div>
  <div
      class="col-md-3 pt-4"
      v-if="invoiceAddressDiffers"
  >
    <Text
        field="invoice_address_additional"
        label="Zusatz 2"
        :value="invoice_address_additional"
    />
  </div>

</template>

<style scoped>

</style>