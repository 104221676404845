import './bootstrap';
import '~bootstrap/dist/js/bootstrap';
import { createApp, defineAsyncComponent } from 'vue/dist/vue.esm-bundler';
// import {createApp} from 'vue';


import DetermineConsumption from './tariff-calculator/DetermineConsumption.vue';
import SecondContractPartner from './tariff-calculator/SecondContractPartner.vue';
import InvoiceAddressDiffers from './tariff-calculator/InvoiceAddressDiffers.vue';
import SelectTermination from './tariff-calculator/SelectTermination.vue';
import BankAccountNameDiffers from './tariff-calculator/BankAccountNameDiffers.vue';

const app = createApp({
    // mounted() {
    //     console.log('The app is working')
    // }
});

app.component('determine-consumption', DetermineConsumption);
app.component('second-contract-partner', SecondContractPartner);
app.component('invoice-address-differs', InvoiceAddressDiffers);
app.component('select-termination', SelectTermination);
app.component('select-termination', SelectTermination);
app.component('bank-account-name-differs', BankAccountNameDiffers);

app.mount('#tariff-calculator-app');



// createApp(DetermineConsumption).mount("#use-determine-consumption");

// import SecondContractPartner from './tariff-calculator/SecondContractPartner.vue';
// createApp(SecondContractPartner).mount("#use-second-contract-partner");

// import ContractAddressDiffers from './tariff-calculator/ContractAddressDiffers.vue';
// createApp(ContractAddressDiffers).mount("#use-contract-address-differs");

// import SelectTermination from './tariff-calculator/SelectTermination.vue';
// createApp(SelectTermination).mount("#use-select-termination");